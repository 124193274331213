import prodConfig from "./prod";
import stageConfig from "./stage";

const CONFIG_ENV = process.env.REACT_APP_CONFIG_ENV || "";
const projectEnv: string = process.env.REACT_APP_ENV || "";

const getSSODomainUrl = (env) => {
  switch (env) {
    case "staging":
    case "development":
      return "https://sso.stage.gamingsphere.xyz";
    case "production":
      return "https://sso-gamingtec.com";
    default:
      throw new Error("Env is not supported.");
  }
};

const getSSOWidgetUrl = (env) => {
  switch (env) {
    case "staging":
    case "development":
      return "https://sso-widget.stage.gamingsphere.xyz";
    case "production":
      return "https://sso-widget.prod.gamingsphere.xyz";
    default:
      throw new Error("Env is not supported.");
  }
};

const getSSOClientId = (env) => {
  switch (env) {
    case "staging":
    case "development":
      return 3;
    case "production":
      return 2;
    default: {
      throw new Error("Env is not supported.");
    }
  }
};

const getBaseApiUrl = (env) => {
  switch (env) {
    case "staging":
      return "https://sso.stage.gamingsphere.xyz";
    case "production":
      return "https://sso-gamingtec.com";
    case "development":
      return "https://sso.stage.gamingsphere.xyz";
  }
};

const getSSORedirectUrl = (env) => {
  switch (env) {
    case "development": {
      return window.location.origin;
    }
    case "staging": {
      return "https://stage.hubgam.com";
    }
    case "production": {
      return "https://prod.hubgam.com";
    }
    default: {
      throw new Error("Env is not supported.");
    }
  }
};

const config = {
  SSO_BASE_API_URL: getBaseApiUrl(projectEnv),
  SSO_DOMAIN_URL: getSSODomainUrl(projectEnv),
  SSO_WIDGET_URL: getSSOWidgetUrl(projectEnv),
  DEFAULT_CLIENT_ID: getSSOClientId(projectEnv),
  REDIRECT_URL: `${getSSORedirectUrl(projectEnv)}/oauth`,
  sentry_dsn:
    "https://2b76cc300ea44e179bd6bcc1e62b4b72@o4505324534300672.ingest.sentry.io/4505510463602688",
};

const configByEnv = CONFIG_ENV === "stage" ? stageConfig : prodConfig;
const mergedConfigs = { ...config, ...configByEnv };

export default mergedConfigs;
