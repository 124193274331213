import * as Yup from "yup";
import {
  isNotEmptyObj,
  isValidEmail,
  isValidGameProviderTextArea,
  isValidPassword,
  isValidPaymentInput,
  isValidSlug,
  isValidDisplayCondition,
  isValidDomain,
} from "./validationHelper";

export const LoginSchema = () =>
  Yup.object().shape({
    email: isValidEmail().required("Email field is required"),
    password: Yup.string().required("Password field is required"),
  });

export const ForgotPasswordSchema = () =>
  Yup.object().shape({
    email: isValidEmail().required("Email field is required"),
  });

export const ResetPasswordSchema = () =>
  Yup.object().shape({
    email: isValidEmail().required("Email field is required"),
    password: isValidPassword().required("Password field is required"),
    repeatPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("New password field is required"),
  });

export const AccountBaseFormSchema = (type) =>
  Yup.object().shape({
    name: Yup.string().required("Name field is required"),
    email: isValidEmail().required("Email field is required"),
    password:
      type === "edit"
        ? isValidPassword()
        : isValidPassword().required("Password field is required"),
  });

export const AccountBrandFormSchema = () =>
  Yup.object().shape({
    brands: Yup.array(),
  });

export const AccountRoleFormSchema = () =>
  Yup.object().shape({
    roles: Yup.array(),
  });

export const CustomerBaseFormSchema = () =>
  Yup.object().shape({
    name: Yup.string().notRequired(),
    firstName: Yup.string().notRequired(),
    lastName: Yup.string().notRequired(),
    country: Yup.string().notRequired(),
    city: Yup.string().notRequired(),
    address: Yup.string().notRequired(),
    postalCode: Yup.string().notRequired(),
    email: isValidEmail().notRequired(),
  });

export const CustomerTagsFormSchema = () =>
  Yup.object().shape({
    vip: Yup.string().notRequired(),
  });

export const CustomerTfaFormSchema = () =>
  Yup.object().shape({
    tfa_type: Yup.string().notRequired(),
    tfa_enabled: Yup.boolean().notRequired(),
  });

export const CustomerRegTypeFormSchema = () =>
  Yup.object().shape({
    player_type: Yup.string().required(),
  });

export const RolePermissionFormSchema = () =>
  Yup.object().shape({
    name: Yup.string().required("Role name is required"),
    permissions: Yup.array().min(1).required("Permissions is required"),
  });

export const CreateBrandFormSchema = () =>
  Yup.object().shape({
    omega_id: Yup.number()
      .typeError("Brand id must be a number")
      .required("Brand id is required"),
    name: Yup.string().required("Brand name is required"),
    token: Yup.string().required("Brand proxy token is required"),
    maintenanceMode: Yup.boolean(),
    settings: Yup.object().shape({
      color: Yup.string().required("Brand color is required"),
      cdn: Yup.string().required("CDN Host is required"),
      ws: Yup.string().notRequired(),
      sb_host: Yup.string().url("SB Host must be valid url").notRequired(),
    }),
    countries: Yup.array().min(1).required("Countries is required"),
    currencies: Yup.array().min(1).required("Currencies is required"),
    languages: Yup.array().min(1).required("Languages is required"),
    country_currency_map: Yup.mixed()
      .test(
        "not empty",
        "Country to currency is required",
        (value) => !!Object.values(value).length
      )
      .required(),
    country_language_map: Yup.mixed()
      .test(
        "not empty",
        "Country to language is required",
        (value) => !!Object.values(value).length
      )
      .required(),
  });

export const CountryListSelectFormSchema = () =>
  Yup.object().shape({
    countries: Yup.array().min(1),
  });

export const LocalesAndCountriesFormSchema = () =>
  Yup.object().shape({
    currencies: Yup.array(),
    languages: Yup.array(),
    countries: Yup.array().min(1),
  });

export const BrandConfigurationSettingsFormSchema = () =>
  Yup.object().shape({
    omega_id: Yup.number()
      .typeError("Brand id must be a number")
      .required("Brand id is required"),
    name: Yup.string().required("Brand name is required"),
    token: Yup.string().required("Brand proxy token is required"),
    maintenanceMode: Yup.boolean(),
    omega_url: Yup.string()
      .notRequired()
      .nullable(),
    settings: Yup.object().shape({
      color: Yup.string().required("Brand color is required"),
      cdn: Yup.string()
        .url("CDN Host must be valid url")
        .required("CDN Host is required"),
      ws: Yup.string().notRequired(),
      sb_host: Yup.string()
        .url("SB Host must be valid url")
        .notRequired()
        .nullable(),
    }),
    countries: Yup.array().min(1),
  });

export const CreateGameFormSchema = () =>
  Yup.object().shape({
    active: Yup.boolean(),
    omega_id_desktop: Yup.string().required(
      "The omega id desktop field is required"
    ),
    slug: isValidSlug().required("Slug is required"),
    omega_id_mobile: Yup.string().required(
      "The omega id mobile field is required"
    ),
    provider: null,
    omega_launch_id_desktop: Yup.string().required(
      "The launch ID desktop field is required"
    ),
    omega_launch_id_mobile: Yup.string().required(
      "The launch ID mobile field is required"
    ),
    omega_start_params_desktop: Yup.string().required("Required"),
    omega_start_params_mobile: Yup.string().required("Required"),
    ratio: Yup.string().notRequired(),
    demo: Yup.boolean(),
    fullscreen: Yup.boolean(),
    title: Yup.object().required("The title field is required"),
    text: Yup.object().notRequired(),
    seo_title: Yup.object().notRequired(),
    seo_description: Yup.object().notRequired(),
    seo_keywords: Yup.object().notRequired(),
    seo_panel_title: Yup.object().notRequired(),
    seo_panel_text: Yup.object().notRequired(),
    canonical: Yup.object().notRequired(),
    alternate: Yup.object().notRequired(),
    ampdomain: Yup.object().notRequired(),
    desktop: Yup.string(),
    mobile: Yup.string(),
    background: Yup.string(),
    badge_new_until: Yup.string().notRequired().nullable(),
    badge_hot_until: Yup.string().notRequired().nullable(),
    show_badge_new: Yup.boolean(),
    show_badge_hot: Yup.boolean(),
  });

export const GameSettingsFormSchema = () =>
  Yup.object().shape({
    slug: isValidSlug().required("Slug is required"),
    provider: null,
    demo: Yup.boolean(),
    ratio: Yup.string().notRequired().nullable(),
    fullscreen: Yup.boolean(),
    title: Yup.object().required("The title field is required"),
    text: Yup.object().notRequired().nullable(),
    seo_title: Yup.object().notRequired().nullable(),
    seo_description: Yup.object().notRequired().nullable(),
    seo_keywords: Yup.object().notRequired().nullable(),
    seo_panel_title: Yup.object().notRequired().nullable(),
    seo_panel_text: Yup.object().notRequired().nullable(),
    canonical: Yup.object().notRequired().nullable(),
    alternate: Yup.object().notRequired().nullable(),
    ampdomain: Yup.object().notRequired().nullable(),
    options: Yup.object().notRequired().nullable(),
    badge_new_until: Yup.string().notRequired().nullable(),
    badge_hot_until: Yup.string().notRequired().nullable(),
    show_badge_new: Yup.boolean(),
    show_badge_hot: Yup.boolean(),
  });

export const GameSettingsGlobalConfigSchema = () =>
  GameSettingsFormSchema().shape({
    omega_id_desktop: Yup.string().required(
      "The omega id desktop field is required"
    ),
    omega_launch_id_desktop: Yup.string().required(
      "The launch ID desktop field is required"
    ),
    omega_start_params_desktop: Yup.string().required("Required"),
    omega_id_mobile: Yup.string().required(
      "The omega id mobile field is required"
    ),
    omega_launch_id_mobile: Yup.string().required(
      "The launch ID mobile field is required"
    ),
    omega_start_params_mobile: Yup.string().required("Required"),
  });

export const GameImagesFormSchema = () => {
  Yup.object().shape({
    desktop: Yup.string(),
    mobile: Yup.string(),
    background: Yup.string(),
  });
};

export const GameCategoriesFormSchema = () => {
  Yup.object().shape({
    id: Yup.number().notRequired(),
    categories: Yup.array().notRequired(),
  });
};

export const SettingsFormSchema = () =>
  Yup.object().shape({
    name: Yup.string(),
    slug: isValidSlug(),
    omega_platforms: Yup.array().notRequired().nullable(),
    title: Yup.object().notRequired().nullable(),
    text: Yup.array().notRequired().nullable(),
    seo_title: Yup.array().notRequired().nullable(),
    seo_description: Yup.array().notRequired().nullable(),
    seo_keywords: Yup.array().notRequired().nullable(),
    seo_panel_title: Yup.object().notRequired().nullable(),
    seo_panel_text: Yup.object().notRequired().nullable(),
    canonical: Yup.object().notRequired().nullable(),
    alternate: Yup.object().notRequired().nullable(),
    ampdomain: Yup.object().notRequired().nullable(),
  });

export const CreateCategoryFormSchema = () =>
  Yup.object().shape({
    slug: isValidSlug(),
    title: isNotEmptyObj(),
    text: Yup.array().notRequired().nullable(),
    seo_title: Yup.object().notRequired().nullable(),
    seo_description: Yup.object().notRequired().nullable(),
    seo_keywords: Yup.object().notRequired().nullable(),
    seo_panel_title: Yup.object().notRequired().nullable(),
    seo_panel_text: Yup.object().notRequired().nullable(),
    canonical: Yup.object().notRequired().nullable(),
    alternate: Yup.object().notRequired().nullable(),
    ampdomain: Yup.object().notRequired().nullable(),
    image: Yup.object()
      .shape({
        normal: Yup.string(),
        active: Yup.string(),
      })
      .notRequired()
      .nullable(),
  });

export const ImagesFormSchema = () => {
  Yup.object().shape({
    normal: Yup.string(),
    active: Yup.string(),
  });
};

export const BrandsFormSchema = () =>
  Yup.object().shape({
    id: Yup.number().notRequired(),
    brands: Yup.array().notRequired(),
  });

export const CreatePromotionFormSchema = () =>
  Yup.object().shape({
    active: Yup.boolean().notRequired().nullable(),
    name: Yup.string().required("Name is required"),
    omega_ids: Yup.array().nullable().notRequired(),
    omega_code: Yup.string().notRequired().nullable(),
    slug: isValidSlug().required("Slug is required"),
    tags: Yup.array().notRequired().nullable(),
    button_color: Yup.object().nullable(),
    button_text: Yup.object().nullable(),
    button_url: Yup.object().nullable(),
    url: Yup.object().notRequired().nullable(),
    title: Yup.object().notRequired().nullable(),
    text_short: Yup.object().notRequired().nullable(),
    text_full: Yup.object().notRequired().nullable(),
    display_condition: Yup.object(),
    seo_title: Yup.object().notRequired().nullable(),
    seo_description: Yup.object().notRequired().nullable(),
    seo_keywords: Yup.object().notRequired().nullable(),
    seo_panel_title: Yup.object().notRequired().nullable(),
    seo_panel_text: Yup.object().notRequired().nullable(),
    canonical: Yup.object().notRequired().nullable(),
    alternate: Yup.object().notRequired().nullable(),
    ampdomain: Yup.object().notRequired().nullable(),
    show_from: Yup.string().notRequired().nullable(),
    show_to: Yup.string().notRequired().nullable(),
  });

export const getPromotionSettingFormSchema = ({ countries, languages }) =>
  Yup.object().shape({
    name: Yup.string().required("Name is required"),
    omega_ids: Yup.array().nullable().notRequired(),
    omega_code: Yup.string().notRequired().nullable(),
    slug: isValidSlug().required("Slug is required"),
    tags: Yup.array().notRequired().nullable(),
    button_color: Yup.object().nullable(),
    button_text: Yup.object().nullable(),
    button_url: Yup.object().nullable(),
    url: Yup.object().notRequired().nullable(),
    title: Yup.object().notRequired().nullable(),
    text_short: Yup.object().notRequired().nullable(),
    text_full: Yup.object().notRequired().nullable(),
    seo_title: Yup.array().notRequired().nullable(),
    seo_description: Yup.array().notRequired().nullable(),
    seo_keywords: Yup.array().notRequired().nullable(),
    seo_panel_title: Yup.object().notRequired().nullable(),
    seo_panel_text: Yup.object().notRequired().nullable(),
    canonical: Yup.object().notRequired().nullable(),
    alternate: Yup.object().notRequired().nullable(),
    ampdomain: Yup.object().notRequired().nullable(),
    display_condition: isValidDisplayCondition({
      countries,
      languages,
    }).required(),
  });

export const PromotionScheduleFormSchema = () =>
  Yup.object().shape({
    show_from: Yup.string().required("Start date is required"),
    show_to: Yup.string().required("End date is required"),
  });

export const CalendarFormSchema = () =>
  Yup.object().shape({
    name: Yup.string().notRequired(),
    slug: Yup.string().required("Slug is required"),
    show_from: Yup.string().required("Start date is required"),
    show_to: Yup.string().required("End date is required"),
    template: Yup.array().notRequired().nullable(),
  });

export const CalendarDayFormSchema = () =>
  Yup.object().shape({
    name: Yup.string().required("Calendar day is required"),
    date: Yup.string().required("Calendar day date is required").nullable(),
    title: Yup.object().notRequired().nullable(),
    text: Yup.object().notRequired().nullable(),
    description: Yup.object().notRequired().nullable(),
    button_text: Yup.object().required("Button text is required"),
    action_type: Yup.object().required("Action type is required"),
    action_value: Yup.object().required("Action value is required"),
    image_active: isNotEmptyObj("Active image is required"),
    image_upcoming: isNotEmptyObj("Upcoming image is required"),
    image_expired: isNotEmptyObj("Expired image is required"),
  });

export const CreateBannerFormSchema = () =>
  Yup.object().shape({
    active: Yup.boolean(),
    name: Yup.string().required("Banner name is required"),
    image: Yup.object().required("Banner image is required"),
    title: Yup.object().nullable(),
    display_position: Yup.object().nullable(),
    display_condition: Yup.object(),
    text: Yup.object().nullable(),
    button_color: Yup.object().nullable(),
    button_text: Yup.object().nullable(),
    button_url: Yup.object().nullable(),
    url: Yup.object().nullable(),
    show_from: Yup.string(),
    show_to: Yup.string(),
    js_banner: Yup.object(),
  });

export const getBannerSettingFormSchema = ({ countries, languages }) =>
  Yup.object().shape({
    name: Yup.string().required("Banner name is required"),
    image: Yup.object().required("Banner image is required"),
    title: Yup.object().nullable(),
    display_position: Yup.object().nullable(),
    display_condition: isValidDisplayCondition({
      countries,
      languages,
    }).required(),
    text: Yup.object().nullable(),
    button_color: Yup.object().nullable(),
    button_text: Yup.object().nullable(),
    button_url: Yup.object().nullable(),
    url: Yup.object().nullable(),
    js_banner: Yup.object(),
  });

export const BannerScheduleFormSchema = () =>
  Yup.object().shape({
    show_from: Yup.string(),
    show_to: Yup.string(),
  });

export const CreateFaqFormSchema = () =>
  Yup.object().shape({
    name: Yup.string().required("Name is required"),
    title: Yup.object().required("The title field is required"),
    description: Yup.object().notRequired().nullable(),
    content: Yup.object().notRequired().nullable(),
  });

export const FaqSettingFormSchema = () =>
  Yup.object().shape({
    name: Yup.string().required("Name is required"),
    title: Yup.object().required("The title field is required"),
    description: Yup.object().notRequired().nullable(),
    content: Yup.object().required(),
  });

export const FaqImagesFormSchema = () => {
  Yup.object().shape({
    image: Yup.string(),
  });
};

export const ProviderCreationFormSchema = () =>
  Yup.object().shape({
    slug: isValidSlug(),
    seo_title: Yup.object().notRequired().nullable(),
    seo_description: Yup.object().notRequired().nullable(),
    seo_keywords: Yup.object().notRequired().nullable(),
    seo_panel_title: Yup.object().notRequired().nullable(),
    seo_panel_text: Yup.object().notRequired().nullable(),
    canonical: Yup.object().notRequired().nullable(),
    alternate: Yup.object().notRequired().nullable(),
    ampdomain: Yup.object().notRequired().nullable(),
  });

export const BonusSettingFormSchema = () =>
  Yup.object().shape({
    id: Yup.number().notRequired().nullable(),
    name: Yup.string().required("Name is required"),
    type: Yup.string()
      .typeError("The trigger type should be selected")
      .required(),
    slug: isValidSlug().required("Slug is required"),
    code: Yup.string().required("Bonus code is required"),
    url: Yup.object().notRequired().nullable(),
    title: Yup.mixed()
      .test(
        "not empty",
        "The title field is required",
        (value) => !!Object.values(value).length
      )
      .required(),
    active: Yup.boolean(),
    show_from: Yup.string().required("Start date is required"),
    show_to: Yup.string().required("End date is required"),
    activations: Yup.number()
      .typeError("The activation field must be a number")
      .moreThan(0, "The activation field must be more then zero")
      .required("The activation field is required"),
    display_condition: Yup.object().when("type", {
      is: "deposit",
      then: Yup.object().shape({
        method_ids: Yup.array()
          .min(1)
          .required("Method is required for deposit type"),
      }),
      otherwise: Yup.object().notRequired(),
    }),
    display_position: Yup.object().notRequired(),
    image: Yup.object()
      .shape({
        normal: Yup.string(),
        active: Yup.string(),
      })
      .notRequired()
      .nullable(),
  });

export const CreatePageFormSchema = () =>
  Yup.object().shape({
    active: Yup.boolean(),
    name: Yup.string().required("Name is required"),
    display_condition: Yup.object(),
    display_position: Yup.object().required("Display position is required"),
    slug: isValidSlug().required("Slug is required"),
    title: Yup.object().required("The title is required"),
    text: Yup.object().notRequired().nullable(),
    seo_title: Yup.object().notRequired(),
    seo_description: Yup.object().notRequired(),
    seo_keywords: Yup.object().notRequired(),
    canonical: Yup.object().notRequired(),
    alternate: Yup.object().notRequired(),
    ampdomain: Yup.object().notRequired(),
    image: Yup.object()
      .shape({
        normal: Yup.string(),
        active: Yup.string(),
      })
      .notRequired()
      .nullable(),
  });

export const getPageSettingFormSchema = ({ countries, languages }) =>
  Yup.object().shape({
    name: Yup.string().required("Name is required"),
    display_condition: isValidDisplayCondition({
      countries,
      languages,
    }).required(),
    display_position: Yup.object().required("Display position is required"),
    slug: isValidSlug().required("Slug is required"),
    url: Yup.string().required("Link is required").nullable(),
    title: Yup.object().required("The title is required"),
    text: Yup.object().notRequired().nullable(),
    seo_title: Yup.object().notRequired(),
    seo_description: Yup.object().notRequired(),
    seo_keywords: Yup.object().notRequired(),
    canonical: Yup.object().notRequired(),
    alternate: Yup.object().notRequired(),
    ampdomain: Yup.object().notRequired(),
    image: Yup.object()
      .shape({
        normal: Yup.string(),
        active: Yup.string(),
      })
      .notRequired()
      .nullable(),
  });

const termsBaseFormFields = {
  name: Yup.string().required("Name is required"),
  slug: isValidSlug().required("Slug is required"),
  secret: Yup.string().notRequired().nullable(),
  title: Yup.object().required("Title is required"),
  text: Yup.object().notRequired().nullable(),
};

export const CreateTermsFormSchema = () =>
  Yup.object().shape({
    ...termsBaseFormFields,
    active: Yup.boolean(),
  });

export const TermsSettingFormSchema = () =>
  Yup.object().shape({
    ...termsBaseFormFields,
  });

export const CreateNewsFormSchema = () =>
  Yup.object().shape({
    active: Yup.boolean(),
    name: Yup.string().required("Name is required"),
    slug: isValidSlug().required("Slug is required"),
    tags: Yup.array().required().min(1, "Tags field must have at least 1 item"),
    title: Yup.object().required("The title is required"),
    text_full: Yup.object().notRequired().nullable(),
    text_short: Yup.object().notRequired().nullable(),
    image: Yup.object().required("Image is required"),
    show_from: Yup.string().required("Start date is required"),
    show_to: Yup.string().required("End date is required"),
    seo_title: Yup.object().notRequired(),
    seo_description: Yup.object().notRequired(),
    seo_keywords: Yup.object().notRequired(),
  });

export const NewsSettingFormSchema = () =>
  Yup.object().shape({
    name: Yup.string().required("Name is required"),
    slug: isValidSlug().required("Slug is required"),
    tags: Yup.array().required().min(1, "Tags field must have at least 1 item"),
    title: Yup.object().required("The title field is required"),
    text_full: Yup.object().notRequired().nullable(),
    text_short: Yup.object().notRequired().nullable(),
    image: Yup.object().required("Image is required"),
    seo_title: Yup.object().notRequired(),
    seo_description: Yup.object().notRequired(),
    seo_keywords: Yup.object().notRequired(),
  });

export const NewsScheduleFormSchema = () =>
  Yup.object().shape({
    show_from: Yup.string().required("Start date is required"),
    show_to: Yup.string().required("End date is required"),
  });

export const GamesFormSchema = () => {
  Yup.object().shape({
    id: Yup.number().notRequired(),
    games: Yup.array().notRequired(),
    providers: Yup.array().notRequired(),
    available: Yup.array().notRequired(),
    active: Yup.array().notRequired(),
  });
};

export const EntityFormSchema = () => {
  Yup.object().shape({
    id: Yup.number().notRequired(),
    providers: Yup.array().notRequired(),
    categories: Yup.array().notRequired(),
    available: Yup.array().notRequired(),
    active: Yup.array().notRequired(),
  });
};

export const BrandPaymentFormSchema = () => {
  Yup.object().shape({
    id: Yup.number().notRequired(),
    payments: Yup.array().notRequired(),
    available: Yup.array().notRequired(),
    active: Yup.array().notRequired(),
  });
};

export const CreateImageFormSchema = () =>
  Yup.object().shape({
    active: Yup.boolean(),
    name: Yup.string().required("Name is required"),
    url: Yup.string().required("Url is required"),
    display_position: Yup.object().required("Display position is required"),
    display_condition: Yup.object(),
    title: Yup.object().required("The title is required"),
    image: Yup.string().required("Image is required"),
  });

export const getImagesSettingFormSchema = ({ countries, languages }) =>
  Yup.object().shape({
    name: Yup.string().required("Name is required"),
    url: Yup.string().required("Url is required"),
    display_position: Yup.object().required("Display position is required"),
    title: Yup.object().required("The title is required"),
    display_condition: isValidDisplayCondition({
      countries,
      languages,
    }).required(),
  });

export const ImagesImageFormSchema = () =>
  Yup.object().shape({
    image: Yup.string().required("Image is required"),
  });

export const UploadModalFormSchema = (validationParams) =>
  Yup.object().shape({
    file: Yup.mixed()
      .test("fileSize", "File size is too large", (value) =>
        value && validationParams.fileSizeLimit
          ? value?.size <= validationParams.fileSizeLimit
          : true
      )
      .test("type", "Unsupported file type", (value) =>
        value && validationParams.types && validationParams.types.length
          ? validationParams.types.includes(value?.type)
          : true
      ),
  });

export const SeoFormSchema = () =>
  Yup.object().shape({
    hreflang: isNotEmptyObj("Hreflang is required"),
    domain_number: Yup.object().notRequired().nullable(),
    canonical_locale: isNotEmptyObj("Canonical is required"),
    alternate_locale: Yup.object().notRequired().nullable(),
    ampdomain_locale: Yup.object().notRequired().nullable(),
  });

export const InfoModalFormSchema = () =>
  Yup.object().shape({
    value: Yup.string().required("Value cannot be empty"),
  });

export const GatewayCreateFormSchema = () =>
  Yup.object().shape({
    active: Yup.boolean(),
    omega_id: Yup.string().required("ID is required"),
    name: Yup.string().required("Name is required"),
    strategy: Yup.object().shape({
      desktop: Yup.string().required("Desktop strategy is required"),
      mobile: Yup.string().required("Mobile strategy is required"),
    }),
    input: isValidPaymentInput(),
    input_v2: isValidPaymentInput(),
  });

export const GatewaySettingsFormSchema = () =>
  Yup.object().shape({
    active: Yup.boolean(),
    omega_id: Yup.string().required("ID is required"),
    name: Yup.string().required("Name is required"),
    strategy: Yup.object().shape({
      desktop: Yup.string().required("Desktop strategy is required"),
      mobile: Yup.string().required("Mobile strategy is required"),
    }),
    input: isValidPaymentInput(),
    input_v2: isValidPaymentInput(),
    transaction_check: Yup.boolean().notRequired(),
  });

export const GatewayPaymentInputSchema = () =>
  Yup.object().shape({
    value: isValidPaymentInput(),
  });

export const GameProviderTextAreaSchema = () =>
  Yup.object().shape({
    value: isValidGameProviderTextArea(),
  });

export const DomainCommentTextAreaSchema = () =>
  Yup.object().shape({
    value: Yup.string().notRequired(),
  });

export const TranslationKeyTextAreaSchema = (checkKeyExist, errMsg) =>
  Yup.object().shape({
    value: Yup.string().test(
      "is-uniq-key",
      errMsg,
      (value) => !checkKeyExist(value)
    ),
  });

export const PaymentFormSchema = () =>
  Yup.object().shape({
    title: Yup.object().notRequired().nullable(),
    name: Yup.string().required("Name is required"),
    slug: isValidSlug().required("Slug is required"),
    info_text: Yup.object().notRequired().nullable(),
    amount_default: Yup.object().notRequired().nullable(),
    amount_series: Yup.object().notRequired().nullable(),
    image: Yup.object().required("Image is required"),
  });

export const PaymentCategoryFormSchema = () =>
  Yup.object().shape({
    active: Yup.boolean(),
    id: Yup.string().notRequired().nullable(),
    slug: isValidSlug().required("Slug is required"),
    name: Yup.string().notRequired(),
    display_position: Yup.object().notRequired().nullable(),
    title: Yup.object().notRequired().nullable(),
    text: Yup.object().notRequired().nullable(),
    seo_title: Yup.object().notRequired().nullable(),
    seo_description: Yup.object().notRequired().nullable(),
    seo_keywords: Yup.object().notRequired().nullable(),
    methods: Yup.array().notRequired(),
    image: Yup.object()
      .shape({
        normal: Yup.string(),
        active: Yup.string(),
      })
      .notRequired()
      .nullable(),
  });

export const PaymentCategoryMethodsSchema = () =>
  Yup.object().shape({
    methods: Yup.object().required("Methods are required"),
  });

export const PaymentFormBrandSchema = () =>
  Yup.object().shape({
    name: Yup.string().required("Name is required"),
    slug: isValidSlug().required("Slug is required"),
  });

export const OrderMethodFormSchema = () =>
  Yup.object().shape({
    methods: Yup.array().required("Methods are required"),
  });

export const GatewayBarabutFormSchema = Yup.object().shape({
  username: Yup.string().required("Username is required"),
  password: Yup.string().required("Password is required"),
  reseller: Yup.string().required("Reseller is required"),
  sender: Yup.string().required("Sender is required"),
  uri: Yup.string().required("URI is required"),
});

export const GatewayPlivoFormSchema = Yup.object().shape({
  auth_id: Yup.string().required("Auth ID is required"),
  auth_token: Yup.string().required("Auth token is required"),
  email: Yup.string().required("Email is required"),
  phone: Yup.string().required("Phone is required"),
});

export const GatewaySmstoFormSchema = Yup.object().shape({
  api_key: Yup.string().required("API key is required"),
  client_id: Yup.string().required("Client ID is required"),
});

export const GatewayTwilioFormSchema = Yup.object().shape({
  sid: Yup.string().required("SID is required"),
  token: Yup.string().required("Token is required"),
  from: Yup.string().required("From field is required"),
});

export const GatewaySmtpFormSchema = Yup.object().shape({
  username: Yup.string().required("Username is required"),
  password: Yup.string().required("Password is required"),
  host: Yup.string().required("Host is required"),
  port: Yup.string().required("Port is required"),
});

export const GatewayMailchimpFormSchema = Yup.object().shape({
  api_key: Yup.string().required("Api key is required"),
  from_email: Yup.string().required("Email is required"),
});

export const ServicePasskeysAuthFormSchema = Yup.object().shape({
  url: isValidDomain().required("Url is required"),
  tenant: isValidDomain().required("Url is required"),
  expiration_time: Yup.number().required("Expiration time is required"),
});

export const ServicePasskeysFormSchema = Yup.object().shape({
  host: isValidDomain().required("Host is required"),
  token: Yup.string().required("Token is required"),
});

export const ServiceZingAIFormSchema = Yup.object().shape({
  url: Yup.string().required("Url is required"),
  zing_brand_id: Yup.string().required("Zing brand id is required"),
  username: Yup.string().required("Username is required"),
  password: Yup.string().required("Password is required"),
});

export const ServiceAingAIFormSchema = Yup.object().shape({
  url: Yup.string().required("Url is required"),
  brand_uuid: Yup.string().required("Wizhdom brand id is required"),
});

export const ServiceChatSupportFormSchema = Yup.object().shape({
  provider: Yup.string().required("Provider is required"),
  livechat: Yup.string().when("provider", {
    is: "livechat",
    then: Yup.string().required("API key is required"),
    otherwise: Yup.string().notRequired().nullable(),
  }),
  zendesk: Yup.string().when("provider", {
    is: "zendesk",
    then: Yup.string().required("API key is required"),
    otherwise: Yup.string().notRequired().nullable(),
  }),
  liveagent: Yup.string().when("provider", {
    is: "liveagent",
    then: Yup.string().required("API key is required"),
    otherwise: Yup.string().notRequired().nullable(),
  }),
});

const eventFieldsSchema = Yup.object({
  enabled: Yup.boolean().required("Enabled status is required"),
  count: Yup.number().required("Events count is required"),
  interval: Yup.number().required("Interval is required"),
});

export const GatewayIovationFormSchema = Yup.object().shape({
  deposit: eventFieldsSchema,
  withdraw: eventFieldsSchema,
  login: eventFieldsSchema,
});

export const GatewayCoveryFormSchema = Yup.object().shape({
  login: eventFieldsSchema,
  signup: Yup.object({
    enabled: Yup.boolean().required("Enabled status is required"),
  }),
  deposit: eventFieldsSchema,
  withdraw: eventFieldsSchema,
  token: Yup.string().required(),
  secret: Yup.string().required(),
  host: Yup.string().required(),
});

const KycServiceSchema = Yup.lazy((obj) =>
  Yup.object(
    Object.keys(obj || {}).reduce((acc, key) => {
      acc[key] = Yup.object().shape({
        option: Yup.string()
          .oneOf(["internal", "kyc_aid"])
          .required("Option is required"),

        kyc_aid: Yup.object()
          .shape({
            token: Yup.string().required("Token is required"),
            form: Yup.object()
              .shape({
                id: Yup.string().required("Form ID is required"),
                name: Yup.string().required("Form name is required"),
              })
              .required("Form is required"),
          })
          .when("option", {
            is: "kyc_aid",
            then: (schema) => schema.required("KYC AID is required"),
            otherwise: (schema) => schema.notRequired().strip(),
          }),

        internal: Yup.object()
          .shape({
            identifier: Yup.object()
              .shape({
                enabled: Yup.boolean().required("Enabled is required"),
                required: Yup.boolean().required("Required is required"),
                documents: Yup.array()
                  .of(Yup.string().oneOf(["id", "passport", "license"]))
                  .required("Documents are required"),
              })
              .required("Identifier is required"),
            verifier: Yup.object()
              .shape({
                enabled: Yup.boolean().required("Enabled is required"),
                required: Yup.boolean().required("Required is required"),
                documents: Yup.array()
                  .of(Yup.string().oneOf(["address statement", "bank bill"]))
                  .required("Documents are required"),
              })
              .required("Verifier is required"),
            card: Yup.object()
              .shape({
                enabled: Yup.boolean().required("Enabled is required"),
                required: Yup.boolean().required("Required is required"),
                sides: Yup.array()
                  .of(Yup.string().oneOf(["front", "back"]))
                  .required("Sides are required"),
              })
              .required("Card is required"),
          })
          .when("option", {
            is: "internal",
            then: (schema) => schema.required("Internal is required"),
            otherwise: (schema) => schema.notRequired().strip(),
          }),
      });
      return acc;
    }, {})
  )
);

export const IntegrationSettingsFieldsFormSchema = Yup.object().when(
  "alias",
  (alias) => {
    switch (alias) {
      case "plivo":
        return GatewayPlivoFormSchema;
      case "barabut":
        return GatewayBarabutFormSchema;
      case "smtp":
        return GatewaySmtpFormSchema;
      case "mailchimp":
        return GatewayMailchimpFormSchema;
      case "smsto":
        return GatewaySmstoFormSchema;
      case "twilio":
        return GatewayTwilioFormSchema;
      case "iovation":
        return GatewayIovationFormSchema;
      case "covery":
        return GatewayCoveryFormSchema;
      case "aing":
        return ServiceAingAIFormSchema;
      case "zing":
        return ServiceZingAIFormSchema;
      case "passkeysAuth":
        return ServicePasskeysAuthFormSchema;
      case "passkeys":
        return ServicePasskeysFormSchema;
      case "chatSupport":
        return ServiceChatSupportFormSchema;
      case "kyc":
        return KycServiceSchema;
    }
  }
);

export const CreateIntegrationFormSchema = Yup.object().shape({
  alias: Yup.string().required("Alias is required"),
  name: Yup.string().required("Name is required"),
  settings: IntegrationSettingsFieldsFormSchema,
});

export const IntegrationSettingsFormSchema = Yup.object().shape({
  alias: Yup.string().required("Alias is required"),
  name: Yup.string().required("Name is required"),
  settings: IntegrationSettingsFieldsFormSchema,
});

const quizBaseFormFields = {
  name: Yup.string().required("Name is required"),
  slug: isValidSlug().required("Slug is required"),
  title: Yup.mixed()
    .test(
      "not empty",
      "The title field is required",
      (value) => !!Object.values(value).length
    )
    .required(),
  description: Yup.object().notRequired().nullable(),
  image: Yup.object().notRequired().nullable(),
  show_from: Yup.string().required("Show from date is required"),
  show_to: Yup.string().required("Show to date is required"),
  active: Yup.boolean(),
  questions: Yup.object().notRequired().nullable(),
};

export const CreateQuizFormSchema = () =>
  Yup.object().shape({ ...quizBaseFormFields });

export const EditQuizFormSchema = () =>
  Yup.object().shape({
    ...quizBaseFormFields,
    topic: Yup.string().required("The topic field is required"),
  });

export const QuizQuestionFormSchema = () =>
  Yup.object().shape({
    id: Yup.string().notRequired(),
    quiz_id: Yup.string().notRequired(),
    title: Yup.mixed()
      .test(
        "not empty",
        "The title field is required",
        (value) => !!Object.values(value).length
      )
      .required(),
    description: Yup.object().notRequired().nullable(),
    image: Yup.object().notRequired().nullable(),
    points: Yup.number().notRequired(),
    answers: Yup.array().notRequired(),
  });

export const QuizQuestionAnswerFormSchema = () =>
  Yup.object().shape({
    id: Yup.string().notRequired(),
    question_id: Yup.string().notRequired(),
    title: Yup.mixed()
      .test(
        "not empty",
        "The title field is required",
        (value) => !!Object.values(value).length
      )
      .required(),
    correct: Yup.boolean().notRequired(),
  });

export const DomainsSchema = () =>
  Yup.object().shape({
    domains: Yup.array().of(
      Yup.object().shape({
        domain: Yup.string()
          .matches(
            /^(?:(?:https?|ftp):\/\/)?(?:www\.)?(localhost|[a-z0-9-]+(?:\.[a-z0-9-]+){1,})$/i,
            "Invalid domain"
          )
          .required("Domain is required"),
      })
    ),
  });
